import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import Img from 'gatsby-image';
import style from './bio.module.scss';
import { RichTextOptions } from '../components/richTextOptions';
import { LinkedinIcon } from '../components/icon';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { findImageByContentId } from '../helpers/contentfulHelpers';
import Contact from '../components/home/home.contact';

const Bio = ({ pageContext, path }) => {
  const { data, assets } = pageContext;
  let photo;
  if(data?.photo) {
    photo = findImageByContentId(assets, data.photo.contentful_id);
  }

  return (
    <PageWithHeader
      title={'Leadership'}
      url={path}
      image={photo?.fluid?.src}
      headerImage={data?.headerImage?.fluid?.src}
    >
      <article
        aria-labelledby="employee-name"
        className={style.personContainer}
      >
        <section className={style.bioHeaderContainer}>
          <Img
            className={style.photo}
            fluid={photo?.fluid}
            fadeIn={false}
            loading="eager"
          />
          <div className={style.personHeader}>
            <h2 className={style.personName}>{data.name}</h2>
            {data.position && (
              <h3 className={style.personPosition}>{data.position}</h3>
            )}
            {data.subTitle && (
              <p className={style.personSubTitle}>{data.subTitle}</p>
            )}

            <div className={style.iconContainer}>
              {data.linkedin && (
                <a href={data.linkedin}>
                  <LinkedinIcon
                    className={style.linkdenIcon}
                  ></LinkedinIcon>
                </a>
              )}
            </div>
          </div>
        </section>
        <section>
          {data?.content?.json &&
            documentToReactComponents(
              data?.content?.json,
              RichTextOptions(assets)
            )}
        </section>
      </article>
    <Contact contactTitle={''} contactLink='/contact' contactButton={'Contact Us'}></Contact>
    </PageWithHeader>

  );
};

export default Bio;
