import { findAssetByContentId, findImageByUrl } from '../helpers/contentfulHelpers';
import Img from 'gatsby-image';
import style from './richTextOptions.module.scss';
import { Quote } from './typography';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

const suportedVideoExts = ['webm', 'mp4'];


export const RichTextOptions: (assets: any) => Options = (assets) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const asset = findAssetByContentId(assets, node.data.target.sys.contentful_id);
      if(asset?.localFile?.childImageSharp) {
        return <div className={style.photo}>
          <div className={style.photoImage} id={asset.contentful_id}><Img fluid={asset.localFile.childImageSharp.fluid} alt={asset.description || asset.title} /></div>
          <label htmlFor={asset.contentful_id}>{asset.description || asset.title}</label>
        </div>;
      }

      if(asset?.localFile?.extension && suportedVideoExts.some(ext => asset.localFile.extension === ext)) {
        return <div className={style.video}>
          <video id={asset.contentful_id} preload="auto" controls>
            <source src={asset.localFile.publicURL} type={asset.localFile.internal.mediaType}></source>
          </video>
          <label htmlFor={asset.contentful_id}>{asset.description || asset.title}</label>
        </div>;
      }
      return <></>;
    },
    [BLOCKS.HEADING_2]: (_, children) => {
      return <h2 className={style.canDoHeader}>{children}</h2>
    },
    [BLOCKS.UL_LIST]: (_, children) => {
      return <ul className={style.canDoList}>{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (node) => {
      return <li>{node.content[0].content[0].value}</li>
    },
    [BLOCKS.QUOTE]: (node, children) => {
      return <Quote className={style.quote}>{children}</Quote>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className={style.paragraph}>{children}</p>
    }
  }
});
